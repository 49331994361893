var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search work orders..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.$route.query.search)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),(!_vm.$root.isPassiveUser())?_c('router-link',{staticClass:"button is-rounded is-flex",attrs:{"to":{
                  name: 'create-work-order'
                }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("Work Order")])],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.workOrders.data),function(workOrder){return _c('div',{key:workOrder.uuid,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"has-text-weight-bold is-block",attrs:{"to":{
                  name: 'work-order-manager',
                  params: {
                    workOrder: workOrder.uuid
                  }
                }}},[_vm._v(" #"+_vm._s(workOrder.number)+" "+_vm._s(workOrder.reference)+" ")]),_c('small',{staticClass:"has-text-grey"},[_vm._v("At "+_vm._s(workOrder.location.name)+", Updated "+_vm._s(_vm._f("asCalendar")(workOrder.updated_at)))])],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('icon',{staticClass:"has-text-grey mr-1",attrs:{"icon":"people-arrows"}}),_c('span',[_vm._v(_vm._s(workOrder.team.name))])],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('circular-progress-bar',{attrs:{"tooltip":"Current progress of the Work Order","total":workOrder.inspections.length,"total-complete":workOrder.inspections.map(inspection => inspection.closed_off_at).length,"backgroundColor":"#212127"}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-center"},[_c('priority-indicator',{attrs:{"priority":workOrder.priority}})],1),_c('column',{staticClass:"is-narrow is-flex is-align-items-center is-justify-content-end"},[_c('outcome-tag',{attrs:{"status":workOrder.status}})],1)],1)],1)}),0)])],1),(_vm.workOrders.data.length)?_c('pager',{attrs:{"pageable":_vm.workOrders,"context":"Work Order","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.workOrders.data.length,"with-link":"","heading":"There are no work orders available for display.","to":{
      name: 'create-work-order'
    },"to-text":"New Work Order"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }